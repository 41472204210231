<template>
  <v-fade-transition mode="out-in">
    <v-layout wrap>
      <div class="wbs-retired">Effective September 2023, please apply <b>CI WBS</b> in <b>MSD</b>. <br>Thank you.</div>
    </v-layout>
  </v-fade-transition>
</template>

<script>
export default {

}
</script>

<style lang="sass">
  .wbs-retired
    margin: calc(40vh - 20px) auto
</style>